<template>
  <div class="page-competition-track">
    <div style="margin-bottom: 16px">
      <el-button type="primary" @click="showAdd">添加赛道</el-button>
    </div>
    <pro-table
      ref="tableRef"
      :columns="columns"
      :fetch="fetch"
      :datapager="false"
    ></pro-table>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="600px">
      <pro-form
        ref="formRef"
        label-width="120px"
        label-suffix=":"
        :model="model"
        :fields="fields"
        :rules="rules"
        :submitter="{
          submitButtonProps: {
            loading
          },
          submitText: '保存',
          resetText: '取消'
        }"
        @submit="submit"
        @reset="dialogVisible = false"
      ></pro-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogTitle: '',
      dialogVisible: false,
      loading: false,
      jsId: this.$route.params.id,
      record: null,
      columns: [
        {
          label: '名称',
          prop: 'name'
        },
        {
          label: '类型',
          prop: 'type',
          render: (h, v) => {
            if (v === 1) return '普通赛道'
            if (v === 2) return '金星奖赛道'
            return '-'
          }
        },
        {
          label: '操作',
          width: '160px',
          render: (h, record) => {
            return (
              <div>
                <el-button type="text" onClick={() => this.toUpdate(record)}>
                  修改
                </el-button>
                <el-popconfirm
                  title="确认删除吗？"
                  onConfirm={() => this.toDelete(record)}
                >
                  <el-button
                    style="margin-left: 10px"
                    slot="reference"
                    type="text"
                  >
                    删除
                  </el-button>
                </el-popconfirm>
              </div>
            )
          }
        }
      ],
      model: {
        name: '',
        type: undefined
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            label: '名称',
            prop: 'name'
          },
          style: {
            width: '360px'
          }
        },
        {
          type: 'radio-group',
          formItemProps: {
            label: '类型',
            prop: 'type'
          },
          children: [
            {
              label: 1,
              text: '普通赛道'
            },
            {
              label: 2,
              text: '金星奖赛道'
            }
          ]
        }
      ],
      rules: {
        name: [{ required: true, message: '名称', trigger: 'change' }],
        type: [{ required: true, message: '类型', trigger: 'change' }]
      }
    }
  },
  methods: {
    fetch() {
      return this.$serve.jsTrack
        .getPageList({
          data: {
            jsId: this.jsId
          }
        })
        .then(([, { records, ...res }]) => {
          return {
            ...res,
            list: records
          }
        })
    },
    showAdd() {
      this.dialogTitle = '添加赛道'
      this.dialogVisible = true
      this.record = null
    },
    toUpdate(record) {
      this.dialogTitle = '修改赛道'
      this.dialogVisible = true
      this.record = record
      this.model = {
        name: record.name,
        type: record.type
      }
    },
    async toDelete(record) {
      const [err] = await this.$serve.jsTrack.delete({
        queryId: record.id
      })
      if (err) return
      this.$message.success('赛道删除成功')
      this.$refs.tableRef.fetchData()
    },
    async submit(data) {
      const params = {
        ...data,
        jsId: this.jsId
      }
      if (this.record) {
        this.update(params)
      } else {
        this.add(params)
      }
    },
    async add(data) {
      this.loading = true
      const [err] = await this.$serve.jsTrack.add({
        data
      })
      this.loading = false
      if (err) return
      this.$message.success('赛道添加成功')
      this.dialogVisible = false
      this.$refs.tableRef.refresh()
      this.$refs.formRef.reset()
    },
    async update(data) {
      this.loading = true
      const [err] = await this.$serve.jsTrack.update({
        data: {
          ...data,
          id: this.record.id
        }
      })
      this.loading = false
      if (err) return
      this.$message.success('赛道修改成功')
      this.$refs.tableRef.refresh()
      this.$refs.formRef.reset()
    }
  }
}
</script>

<style lang="scss" scoped></style>
